import api from '@/api/api'
import config from '@/config'

export async function getDetailOrganization(id) {
  return await api.get(`${config.backend}/system/organizations/${id}`)
}

export async function getListOrganization(data) {
  return await api.get(`${config.backend}/system/organizations`, data)
}

export async function createOrganization(data) {
  return await api.post(`${config.backend}/system/organizations`, data)
}

export async function updateOrganization(id, data) {
  return await api.put(`${config.backend}/system/organizations/${id}`, data)
}

export async function deleteOrganization(id) {
  return await api.delete(`${config.backend}/system/organizations/${id}`)
}

export async function updateOrganizationPermission(id, data) {
  return await api.put(`${config.backend}/system/organizations/${id}/permissions`, data)
}

export async function getMenuOrganization(id) {
  return await api.get(`${config.backend}/system/organizations/${id}/menus`)
}

export async function updateMenuOrganization(id, data) {
  return await api.put(`${config.backend}/system/organizations/${id}/menus`, data)
}

export async function getOrganizationPermission(id) {
  return await api.get(`${config.backend}/system/organizations/${id}/permissions`)
}
export async function updateSettingOrganization(id, data) {
  return await api.put(`${config.backend}/system/organizations/${id}/setting-properties`, data)
}
export async function updateServiceOrganization(id, data) {
  return await api.put(`${config.backend}/system/organizations/${id}/services`, data)
}
export async function getServiceOrganization(id) {
  return await api.get(`${config.backend}/system/organizations/${id}/services`)
}
