<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <Message />
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div style="height: 85vh; width: 100%; background: var(--v-bgContainer-base); border-radius: 12px">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <div class="fill-height overflow-y-hidden px-5 d-flex flex-column" column>
            <div
              style="flex: none; height: 88px; width: 100%; text-align: center; border-bottom: 1px solid rgba(93, 101, 136, 0.5)"
            >
              <v-layout align-center class="fill-height px-5">
                <v-btn class="mr-2" dark fab small @click="$router.push('/admin')">
                  <v-icon>icon-arrow_left</v-icon>
                </v-btn>
                <h3 class="text-uppercase">ORDER LIST</h3>
                <v-spacer />
                <div>{{ totalData }} orders</div>
              </v-layout>
            </div>
            <div class="pt-5 d-flex flex-column" style="overflow-y: auto; overflow-x: hidden;">
              <div>
                <v-row>
                  <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <v-autocomplete
                      v-model="filter.service_ids"
                      :items="filterData.services"
                      clearable
                      dense
                      hide-details
                      item-text="name"
                      item-value="id"
                      multiple
                      outlined
                      placeholder="Service"
                      @change="handChangePage(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <v-autocomplete
                      v-model="filter.organization_ids"
                      :items="filterData.organizations"
                      clearable
                      dense
                      hide-details
                      item-text="name"
                      item-value="id"
                      multiple
                      outlined
                      placeholder="Organization"
                      @change="handChangePage(1)"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <v-autocomplete
                      v-model="filter.project_ids"
                      :items="filterData.projects"
                      clearable
                      dense
                      hide-details
                      item-text="name"
                      item-value="id"
                      multiple
                      outlined
                      placeholder="Project"
                      @change="handChangePage(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <v-autocomplete
                      v-model="filter.aoi_ids"
                      :items="filterData.aois"
                      clearable
                      dense
                      hide-details
                      item-text="name"
                      item-value="id"
                      multiple
                      outlined
                      placeholder="AOI"
                      @change="handChangePage(1)"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <v-autocomplete
                      v-model="filter.image_sources"
                      :items="filterData.image_sources"
                      clearable
                      dense
                      hide-details
                      multiple
                      outlined
                      placeholder="Image source"
                      @change="handChangePage(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <v-autocomplete
                      v-model="filter.frequencies"
                      :items="filterData.frequencies"
                      clearable
                      dense
                      hide-details
                      multiple
                      outlined
                      placeholder="Frequencies"
                      @change="handChangePage(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <v-autocomplete
                      v-model="filter.status"
                      :items="filterData.status"
                      clearable
                      dense
                      hide-details
                      multiple
                      outlined
                      placeholder="Status"
                      @change="handChangePage(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                    <DateRangerPicker
                      v-model="filter.time"
                      label="Select time"
                      :defaultNull="true"
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="pb-2 mt-4" style="overflow-y: auto;">
                <v-simple-table>
                  <v-data-table
                    :headers="headers"
                    :items="orders"
                    :options.sync="options"
                    class="elevation-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.time`]="{ item }">
                      <div>{{ transformDate(item.from_date) }} - {{ transformDate(item.to_date) }}</div>
                    </template>

                    <template v-slot:[`item.project`]="{ item }">
                      <div>
                        <div style="font-size: 13px;">
                          Project: <b>{{ item.aoi && item.aoi.project ? item.aoi.project.name : '' }}</b>
                        </div>
                        <div style="font-size: 13px;">
                          AOI: {{ item.aoi ? item.aoi.name : '' }} /
                          <span v-html="transformAreaAoi(item.aoi)"></span>
                        </div>
                      </div>
                    </template>
                    <template #[`item.status`]="{ item }">
                      <v-chip :color="getStatusColor(item.status)" class="text-capitalize" label small>
                        {{ item.status }}
                      </v-chip>
                    </template>

                    <template #[`item.detail`]="{ item }">
                      <v-btn icon @click="showDetail(item)">
                        <v-icon>icon-alert_circle</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-simple-table>
              </div>
              <div class="mt-3 pb-2 d-flex justify-end">
                <v-pagination
                  v-model="page"
                  :length="totalPage"
                  :total-visible="10"
                  circle
                  @input="handChangePage"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </v-layout>
    </v-container>
    <DetailDialog ref="detailDialog" :transformAreaAoi="transformAreaAoi"/>
  </v-layout>
</template>

<script>
import Message from '@/components/Message.vue'
import { getAllAOI, getDataFilterOrder, getOrderAdmin } from '@/api/order'
import { getListOrganization } from '@/api/organization'
import { getServices } from '@/api/services'
import { getAllProjects } from '@/api/project-api'
import convertDate from '@/utils/convertDate'
import DeleteDialog from '@/components/DeleteDialog.vue'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import { mapState } from '@/store/ults'
import Status from '@/utils/status'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import DetailDialog from './detailDialog.vue'
import AreaTransform from '@/utils/convertArea'

export default {
  name: 'User',
  components: {
    AppBarUserMenu,
    ThemeSwitcher,
    DeleteDialog,
    Message,
    DateRangerPicker,
    DetailDialog,
  },
  data() {
    return {
      keyword: undefined,
      loading: false,
      headers: [
        {
          text: 'Service',
          align: 'start',
          sortable: false,
          value: 'service_name',
        },
        { text: 'Created at', value: 'created_at', sortable: true },
        { text: 'Organization', value: 'organization', sortable: false },
        { text: 'Project/AOI', value: 'project', sortable: false },
        { text: 'Time', value: 'time', sortable: false },
        { text: 'Image source', value: 'image_source', sortable: false },
        { text: 'Frequency', value: 'frequency', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Detail', value: 'detail', sortable: false, align: 'center' },
      ],
      options: {},
      orders: [],
      page: 1,
      perPage: 8,
      totalPage: 1,
      roles: [],
      totalData: 0,
      filterData: {
        services: [],
        aois: [],
        projects: [],
        organizations: [],
        frequencies: [],
        image_sources: [],
        status: [],
      },
      filter: {
        status: [],
        image_sources: [],
        frequencies: [],
        time: [],
        organization_ids: [],
        project_ids: [],
        aoi_ids: [],
        service_ids: [],
      },
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  watch: {
    'filter.project_ids': {
      handler(val) {
        this.filter.aoi_ids = []
        this.getListAOI()
      },
      deep: true,
    },
    'filter.time': {
      handler(val) {
        if (val && val.length == 2) {
          this.handChangePage(1)
        }
      },
      deep: true,
    },
    options: {
      handler() {
        this.getListOrder()
      },
      deep: true,
    },
  },
  mounted() {
    this.getListOrder()
    this.getDataFilter()
  },
  methods: {
    async getListOrder() {
      try {
        this.loading = true
        const fitler = {}
        for (const property in this.filter) {
          if (this.filter[property] && this.filter[property].length > 0) {
            fitler[property] = this.filter[property]
          }
        }
        const res = await getOrderAdmin({
          per_page: this.perPage,
          page: this.page,
          sort: this.options.sortBy[0]
            ? !this.options.sortDesc[0]
              ? this.options.sortBy[0]
              : `-${this.options.sortBy[0]}`
            : undefined,
          ...fitler,
        })
        this.orders = res.data.data
        this.totalPage = res.data.last_page
        this.totalData = res.data.total
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    showDetail(item) {
      this.$refs.detailDialog.showDetail(item)
    },
    async getDataFilter() {
      this.getOrganization()
      this.getService()
      this.getListProject()
      this.getListAOI()
      let data = await getDataFilterOrder()
      this.filterData.frequencies = data.data.frequencies
      this.filterData.status = data.data.status
      this.filterData.image_sources = data.data.image_sources
    },
    transformDate(date) {
      return convertDate.dateFormatted(date)
    },
    async getOrganization() {
      let data = await getListOrganization({ perPage: 'all' })
      this.filterData.organizations = data.data.data
    },
    async getService() {
      let data = await getServices()
      this.filterData.services = data.data
    },
    async getListProject() {
      const res = await getAllProjects()
      this.filterData.projects = res.data
    },
    async getListAOI() {
      let data = await getAllAOI({ project_ids: this.filter.project_ids })
      this.filterData.aois = data.data
    },
    getStatusColor(status) {
      return Status.getColor(status)
    },
    async getUserRole() {
      let data = await getRole()
      this.roles = data.data
    },
    async handChangePage(page) {
      this.page = page
      this.getListOrder()
    },
    showEdit(data) {
      this.$refs.createDialog.showEdit(data)
    },
    transformAreaAoi(aoi) {
      let convertArea = ''
      if (aoi) {
        convertArea = AreaTransform.transformUnit(aoi.area)
      }
      return convertArea
    }
  }
}
</script>

<style scoped></style>
