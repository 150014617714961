<template>
  <v-dialog v-model="dialog" width="550">
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        {{ title }}
      </v-card-title>

      <v-card-text v-if="!loading">
        <div style="max-height: 500px; overflow-y: auto;">
          <pre v-if="typeof resultData === 'object'">{{ JSON.stringify(resultData, null, 2) }}</pre>
          <pre v-else>{{ resultData }}</pre>
        </div>
      </v-card-text>
      <v-card-text v-else class="d-flex align-center justify-center" style="height: 500px; overflow-y: auto;">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn icon text @click="copyData">
          <v-icon size="20">mdi-content-copy</v-icon>
        </v-btn>
        <v-btn v-if="apiLink" icon text @click="reloadData">
          <v-icon size="23">mdi-reload</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resultData: {},
    title: '',
    apiLink: null,
    loading: false,
  }),
  methods: {
    show(title, data, apiLink) {
      this.apiLink = apiLink
      this.title = title
      this.resultData = data ? data : {}
      this.dialog = true
    },
    copyData() {
      navigator.clipboard.writeText(JSON.stringify(this.resultData))
    },
    async reloadData() {
      if (this.apiLink) {
        this.loading = true
        var data = await fetch(this.apiLink)
        this.resultData = data.ok ? await data.text() : []
        this.loading = false
      }
    },
  },
}
</script>

<style></style>
