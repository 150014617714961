<template>
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Upload data
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submit">
            <div v-for="(param, index) in params" :key="index">
              <v-text-field
                v-if="param.type === 'string'"
                :label="param.name"
                v-model="dataParams[param.code]"
                outlined
                :rules="[rules.required]"
                dense
              >
              </v-text-field>
            </div>
            <div class="" style="width: 100%; height: 175px;border-radius: 10px;">
              <DragBox :accept-types="acceptTypes" :files.sync="files"/>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            small
            @click="submit"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import DragBox from '@/components/DragBox.vue'
import { uploadData } from '@/api/order'

export default {
  components: {
    DragBox,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      task: {},
      params: [],
      dataParams: {},
      valid: false,
      rules: {
        required: value => !!value || 'Field is required.',
      },
      files: [],
      acceptTypes: '.geojson,.tiff,.tif',
    }
  },
  methods: {
    clearData() {
      this.dataParams = {}
      this.params = []
      this.files = []
    },
    open(task=[]) {
      this.clearData()
      this.dialog = true
      this.params = task?.other_info?.params || []
      this.task = task
    },
    getFormData(datas, files) {
      const formData = new FormData()
      formData.append('file', files[0])
      for (let key in datas) {
        formData.append(key, datas[key])
      }
      return formData
    },
    async submit() {
      try {
        this.loading = true
        this.$refs.form.validate()
        if (!this.valid) return
        if (this.files.length === 0) {
          this.$store.commit('message/SHOW_ERROR', 'Please upload file')
          return
        }
        const formData = this.getFormData(this.dataParams, this.files)
        await uploadData(this.task.uuid, formData)
        this.dialog = false
      } catch(e) {
        console.warn('Error in submit', e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="">

</style>
