<template>
  <v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg">
        <v-toolbar-title>Order infor</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn :loading="loading" class="mt-3 mr-2" dark icon @click="getPipelineTask">
            <v-icon size="30">mdi-reload</v-icon>
          </v-btn>
          <v-btn class="mt-3 mr-1" dark icon @click="showDialog = false">
            <v-icon dark size="35">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="mb-4">
        <div class="d-flex justify-space-between align-center mt-1">
          <v-subheader>Order service: <b class="pl-2">{{ order.service_name }}</b></v-subheader>
          <div class="mr-4">
            <v-chip :color="getStatusColor(order.status)" class="text-capitalize" label>
              {{ order.status }}
            </v-chip>
            <v-btn v-if="order.status === 'failed'" class="ml-3" outlined small
              @click="showResult('Message error', order.error)">
              <v-icon class="mr-2" size="18">mdi-bug</v-icon>
              Error message
            </v-btn>
          </div>
        </div>

        <div class="px-4">
          <v-row style="font-size: 13px">
            <v-col v-for="item in orderInforLabel" :key="item.key" cols="12" lg="6" md="6" sm="6" xl="4" xs="12">
              <div class="d-flex">
                <div class="label-order">{{ item.label }}:</div>
                <div v-if="item.formatDate">{{ transformDate(order[item.key]) }}</div>
                <div v-else>{{ order[item.key] }}</div>
              </div>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" xl="4" xs="12">
              <div class="d-flex">
                <div class="label-order">Project:</div>
                <div>{{ order.aoi && order.aoi.project ? order.aoi.project.name : '' }}</div>
              </div>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6" xl="4" xs="12">
              <div class="d-flex">
                <div class="label-order">AOI:</div>
                <div>
                  {{ order.aoi ? order.aoi.name : '' }} /
                  <span v-html="transformAreaAoi(order.aoi)"></span>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <v-divider></v-divider>
      <v-list subheader three-line>
        <div class="d-flex justify-space-between align-center">
          <v-subheader>Pipeline task</v-subheader>
          <div v-if="pipelines" class="pr-4" style="font-size: 14px; font-weight: bold">
            {{ pipelines.length }} Pipeline
          </div>
        </div>
        <div v-if="loading" class="d-flex align-center justify-center" style="height: 300px">
          <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
        </div>
        <div v-else>
          <div v-if="pipelines && pipelines.length" class="px-4">
            <v-data-iterator :items="pipelines" :items-per-page.sync="itemsPerPage" :page.sync="page"
              hide-default-footer>
              <template v-slot:default="props">
                <div class="pipline-content">
                  <div v-for="(pipeline, index) in props.items" :key="pipeline.id">
                    <div class="d-flex align-center justify-space-between mb-2 mt-4">
                      <div class="d-flex align-center">
                        <div class="mr-4" style="font-size: 14px">
                          #ID: {{ pipeline.id }}. Pipeline
                          {{ index + 1 }}
                        </div>
                        <v-chip :color="getStatusColor(pipeline.status)" class="text-capitalize" label small>
                          {{ pipeline.status }}
                        </v-chip>
                      </div>

                      <div style="font-size: 14px; font-weight: bold">{{ transformDate(pipeline.date) }}</div>
                    </div>
                    <v-stepper v-if="pipeline.tasks && pipeline.tasks.length" alt-labels class="mb-3 px-4 pb-4">
                      <v-stepper-header>
                        <template v-for="(item, index) in pipeline.tasks">
                          <div :key="item.uuid">
                            <v-stepper-step :complete="item.status === 'success'"
                              :rules="[() => item.status !== 'failed']" :step="index + 1">
                            </v-stepper-step>
                            <v-card class="px-4 py-3" style="width: 370px; font-size: 13px">
                              <div class="d-flex mb-2">
                                <div class="label-order" style="color: white; font-weight: bold">
                                  Step {{ index + 1 }}:
                                </div>
                                <v-chip :color="getStatusColor(item.status)" label small>
                                  {{ item.status }}
                                </v-chip>
                              </div>
                              <div class="d-flex mb-2">
                                <div class="label-order">Name:</div>
                                <div>{{ item.name }}</div>
                              </div>
                              <div class="d-flex mb-2">
                                <div class="label-order">Start at:</div>
                                <div>{{ transformDateTime(item.start_at) }}</div>
                              </div>
                              <div class="d-flex mb-2">
                                <div class="label-order">End at:</div>
                                <div>{{ transformDateTime(item.end_at) }}</div>
                              </div>
                              <div class="d-flex mb-2">
                                <div class="label-order">JOB_ID:</div>
                                <div style="font-size: 12px">{{ item.job_id }}</div>
                              </div>
                              <div class="d-flex mb-2">
                                <div class="label-order">Type:</div>
                                <div>{{ item.type }}</div>
                              </div>
                              <div class="d-flex align-center mb-2">
                                <div class="label-order">Payload:</div>
                                <div style="flex: 1">
                                  <v-btn icon small @click="showResultAsync('Payload', item.uuid, 'payload')">
                                    <v-icon>icon-eye</v-icon>
                                  </v-btn>
                                </div>
                                <!-- <v-btn icon small @click="copyData(item.payload)">
                                <v-icon size="20">mdi-content-copy</v-icon></v-btn
                              > -->
                              </div>
                              <div class="d-flex align-center mb-2">
                                <div class="label-order">Result:</div>
                                <div style="flex: 1">
                                  <v-btn icon small @click="showResultAsync('Result', item.uuid, 'result')">
                                    <v-icon>icon-eye</v-icon>
                                  </v-btn>
                                </div>
                                <!-- <v-btn icon small @click="copyData(item.result)">
                                <v-icon size="20">mdi-content-copy</v-icon></v-btn
                              > -->
                              </div>
                              <div v-if="item.error" class="d-flex align-center">
                                <div class="label-order">Error:</div>
                                <div style="flex: 1">
                                  <v-btn outlined small @click="showResult('Message error', item.error)">
                                    message
                                  </v-btn>
                                </div>
                              </div>
                              <div v-if="item.status !== 'created'" class="d-flex align-center mt-4">
                                <div class="label-order">Log:</div>
                                <div style="flex: 1">
                                  <v-btn outlined small @click="showResultAsync('Log detail', item.job_id, 'error')">
                                    Log detail
                                  </v-btn>
                                </div>
                              </div>
                              <div v-if="item.status === 'failed'" class="d-flex align-center mt-4">
                                <div class="label-order">Resubmit:</div>
                                <v-btn :loading="taskResubmit[uuid]" outlined small @click="reSumitTask(item.uuid)">
                                  <v-icon left>mdi-reload</v-icon>
                                  Try again
                                </v-btn>
                              </div>
                              <div
                                v-if="item.status === 'created' && (item.type != 'upload-image' && item.type !== 'upload-vector')"
                                class="d-flex align-center mt-4">
                                <div class="label-order">Run:</div>
                                <v-btn :loading="taskRunLoading[item.uuid]" outlined small tile
                                  @click="runOrder(item.uuid)">
                                  <v-icon left>mdi mdi-play-circle-outline</v-icon>
                                  Run order
                                </v-btn>
                              </div>

                              <div
                                v-if="item.status === 'created' && (item.type === 'upload-image' || item.type === 'upload-vector')"
                                class="d-flex align-center mt-4">
                                <div class="label-order">Upload:</div>
                                <v-btn outlined small @click="openUploadDialog(item)">
                                  <v-icon left>mdi-upload</v-icon>
                                  Upload form data
                                </v-btn>
                              </div>
                            </v-card>
                          </div>
                          <v-divider v-if="index + 1 < pipeline.tasks.length" :key="index"
                            style="border-color: #979a9a"></v-divider>
                        </template>
                      </v-stepper-header>
                    </v-stepper>
                  </div>
                </div>

              </template>
              <template v-slot:footer>
                <v-row align="center" class="my-2 px-3 footer" justify="center">
                  <span class="grey--text">Items per page</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="ml-2" color="primary" dark text v-bind="attrs" v-on="on">
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index"
                        @click="updateItemsPerPage(number)">
                        <v-list-item-title>{{ number }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-spacer></v-spacer>

                  <span class="mr-4grey--text"> Page {{ page }} of {{ numberOfPages }} </span>
                  <v-btn class="mr-1" color="blue darken-3" dark fab small @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn class="ml-1" color="blue darken-3" dark fab small @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </template>
            </v-data-iterator>
          </div>
          <div v-else class="d-flex flex-column align-center justify-center" style="height: 300px">
            <v-icon size="80">mdi mdi-database-off</v-icon>
            <div class="mt-2">No pipeline data</div>
          </div>
        </div>
      </v-list>
      <ResultDialog ref="resultDialog" />
      <UploadDialog ref="uploadDialog" />
    </v-card>
  </v-dialog>
</template>

<script>
import Status from '@/utils/status'
import convertDate from '@/utils/convertDate'
import { getPipelineAdmin, getPropertyTask, reSumitTask, runOrder } from '@/api/order'
import ResultDialog from './resultDialog.vue'
import config from '@/config'
import UploadDialog from './UploadDialog.vue'

export default {
  props: {
    transformAreaAoi: Function,
  },
  components: { ResultDialog, UploadDialog },
  data: () => ({
    showDialog: false,
    order: {},
    page: 1,
    itemsPerPage: 30,
    orderInforLabel: [
      { key: 'created_at', label: 'Created at' },
      { key: 'organization', label: 'Organization' },
      { key: 'frequency', label: 'Frequency' },
      { key: 'service_group', label: 'Service group' },
      { key: 'from_date', label: 'From date', formatDate: true },
      { key: 'to_date', label: 'To date', formatDate: true },
      { key: 'owner', label: 'User/Email' },
    ],
    pipelines: [],
    taskResubmit: {},
    taskRunLoading: {},
    loading: false,
    itemsPerPageArray: [5, 15, 30, 60],
  }),
  computed: {
    numberOfPages() {
      return Math.ceil(this.pipelines.length / this.itemsPerPage)
    },
  },
  watch: {
    showDialog() {
      this.page = 1
    },
  },
  methods: {
    openUploadDialog(task) {
      this.$refs.uploadDialog.open(task)
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    async getPipelineTask() {
      this.loading = true
      let data = await getPipelineAdmin({ order_id: this.order.id })
      this.pipelines = data.data
      this.loading = false
    },
    showDetail(order) {
      this.showDialog = true
      this.order = order
      this.getPipelineTask()
    },
    transformDate(date) {
      if (!date) return '--'
      return convertDate.dateFormatted(date)
    },
    transformDateTime(date) {
      if (!date) return '--'
      return convertDate.dateTimeFormatted(date)
    },
    getStatusColor(status) {
      return Status.getColor(status)
    },
    async showResultAsync(title, uuid, type) {
      var linkReload = null
      if (type === 'error') {
        linkReload = `${config.upload_url}/tasks/${uuid}/log`
        var data = await fetch(linkReload)
        data = data.ok ? await data.text() : []
      } else var { data } = await getPropertyTask(uuid, { property: type })
      this.showResult(title, data, linkReload)
    },
    showResult(title, data, linkApi = null) {
      this.$refs.resultDialog.show(title, data, linkApi)
    },
    copyData(data) {
      navigator.clipboard.writeText(JSON.stringify(data))
    },
    async reSumitTask(uuid) {
      try {
        this.taskResubmit = {
          ...this.taskResubmit,
          [uuid]: true,
        }
        await reSumitTask(uuid)
        await this.getPipelineTask()
        this.taskResubmit = {
          ...this.taskResubmit,
          [uuid]: false,
        }
      } catch (error) {
        this.taskResubmit = {
          ...this.taskResubmit,
          [uuid]: false,
        }
      }
    },
    async runOrder(uuid) {
      try {
        this.taskRunLoading = {
          ...this.taskRunLoading,
          [uuid]: true,
        }
        await runOrder(uuid)
        await this.getPipelineTask()
        this.taskRunLoading = {
          ...this.taskRunLoading,
          [uuid]: false,
        }
      } catch (error) {
        this.taskRunLoading = {
          ...this.taskRunLoading,
          [uuid]: false,
        }
      }
    },
  },
}
</script>

<style>
.label-order {
  width: 90px;
  color: #d0d3d4;
}

.pipline-content {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
}

.footer {
  position: sticky;
  bottom: 0;
  background-color: #333649;
}
</style>
